import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import CalendarDouble from '@/components/CalendarDouble/CalendarDouble.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import ReasonModal from '@/components/ReasonModal/ReasonModal.vue';
import Badge from '@/components/Badge/StatusBadge.vue';

import CommonConst from '@/constants/CommonConst';
import ScheduleConst from '@/constants/ScheduleConst';

import ScheduleService from '@/services/API/schedule.service';
import socketClient from '@/services/SOCKET';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin.js';

import moment from 'moment';
import { mapState } from 'vuex';
import { sendNotify, toLineMessageTemplate } from '@/utilities';

const SELECTIVE_CONTENT = '「 スケジュール 」を承認しますか？';
const PLAIN_NOTI_CONTENTS = ' 「 見積書作成 」に進みます';

const NUMBER_OBJECT_CHANGE = 1;

export default {
	name: 'ScheduleScreen',
	mixins: [redDotNotifyMixin],
	props: {
		mobileView: {
			type: Boolean,
		},
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			scheduleReasonModalId: 'schedule-reason-modal',
			middleContent: ' 様 ／ ',
			screenIcon: 'schedule-icon',
			screenName: 'スケジュール',
			eventCategories: [...ScheduleConst.EVENT_CATEGORIES],

			isShowMainBody: false,
			selectiveContents: SELECTIVE_CONTENT,
			plainContents: '',
			remarksContent: '',
			isDataExisted: false,
			showBottomButton: false,
			scheduleStatus: '',
			scheduleInfo: {
				comment: '',
				confirm_flag: 0,
				delivery_date: null,
				creative_end_date: null,
				creative_start_date: null,
				estimate_end_date: null,
				estimate_start_date: null,
				project_id: '',
				proposal_end_date: null,
				proposal_start_date: null,
				decision_end_date: null,
				decision_start_date: null,
				update_user: '',
				//add 01/08/2023 by HoaDQ
				title_creative: '',
				title_proposal: '',
			},
			nextProcess: 'hearing',
			calendarEvents: ScheduleConst.CALENDAR_EVENTS,
			plainModalId: 'schedule-plain-modal',
			selectiveModalId: 'schedule-selective-modal',
			showStatusBadge: false,
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			// Show main content after fetch data from server successfully
			isShowContent: false,
			totalEstimate: undefined,
		};
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,
		CalendarDouble,

		BottomButton,
		ReasonModal,
		Badge,
	},
	computed: {
		// Get data from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'clientMode',
			'creatorInfo',
			'managementMasterInfo',
			'preview',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}schedule`
						: `${this.projectInfo['process_client_url']}schedule`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}schedule`
					: `${this.projectInfo['process_client_url']}schedule`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	watch: {
		// Binding data to display on calendar
		eventCategories: {
			handler: function (newVal) {
				const reflectEvents = newVal.filter((event) => {
					return (
						(event.datePickerData.dateRange.start !== '' &&
							event.datePickerData.dateRange.end !== '') ||
						event.datePickerData.selectedDate !== ''
					);
				});
				for (const element of reflectEvents) {
					// Get index's event of eventsCategories
					let eventIndex = element.id - 1;

					if (this.calendarEvents[eventIndex].categoryId === element.id) {
						this.calendarEvents[eventIndex].start =
							element.datePickerData.dateRange.start;
						this.calendarEvents[eventIndex].end = element.datePickerData.dateRange.end;
						if (eventIndex === ScheduleConst.DELIVERY_INDEX) {
							this.calendarEvents[eventIndex].start =
								element.datePickerData.selectedDate;
							this.calendarEvents[eventIndex].end = element.datePickerData.selectedDate;
						}
					}
				}
			},
			deep: true,
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		scheduleStatus(newStatus) {
			if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.setRedDotNotiy(CommonConst.SCREEN_ID.SCHEDULE);
			} else {
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.SCHEDULE);
			}
		},
	},
	methods: {
		onConfirm() {
			this.$bvModal.show(`${this.selectiveModalId}`);
			this.plainContents = SELECTIVE_CONTENT;
		},

		async onAcceptConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			let scheduleInfo = {
				status: CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM,
			};
			await this._updateScheduleData(scheduleInfo);
			this.$bvModal.show(`${this.plainModalId}`);
			this.plainContents = PLAIN_NOTI_CONTENTS;
			this.showBottomButton = false;
			this.showStatusBadge = false;
			this.scheduleStatus = CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM;

			// // send mail to creator case 13
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「スケジュール」確定`;
			// this.mailContent = `「スケジュール」が\n承認されました。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「スケジュール」確定`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「スケジュール」確定`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「スケジュール」確定`;
			// this.mailContent = `「スケジュール」を\n承認しました。`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);
			socketClient.send('schedule_confirm', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Get Schedule Info from DB
		 * @param {Number} projectId
		 */
		async _getScheduleInfo(projectId) {
			try {
				let response = await ScheduleService.get(projectId);
				if (response && response.status === 200) {
					console.log('Get schedule success', response.data);
					const scheduleStatus = response.data['status'];
					this.scheduleStatus = scheduleStatus;
					//add code get title_creative, title_proposal 01/08/2023 by HoaDQ
					this.title_creative = response.data['title_creative'];
					this.title_proposal = response.data['title_proposal'];
					const estimateInfo = [
						response.data['estimate_estimate_hour'],
						response.data['decision_estimate_hour'],
						response.data['proposal_estimate_hour'],
						response.data['creative_estimate_hour'],
						response.data['delivery_estimate_hour'],
					];
					if (estimateInfo.includes(null)) {
						return;
					} else {
						let sum = 0;
						for (let index = 0; index < estimateInfo.length; index++) {
							sum += parseFloat(estimateInfo[index]);
						}
						sum = sum.toFixed(2);
						if (parseInt(sum) == sum) {
							sum = parseInt(sum);
						} else if (parseInt(sum * 10) == sum * 10) {
							sum = parseInt(sum * 10) / 10;
						}
						this.totalEstimate = sum;
					}
					this.onCheckScheduleStatus(scheduleStatus);
					this._filterEventCategories(response.data);
				}
			} catch (error) {
				console.log(`Get schedule failed: ${error}`);
			}
		},

		/**
		 * Format Date string
		 * @param {String} date
		 * @returns Data in Japanese format 2021年9月18日
		 */
		_formatDateString(date) {
			if (date && typeof date === 'string') {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');

				return `${year}年${month}月${day}月`;
			}
			return date;
		},

		async _updateScheduleData(scheduleInfo) {
			try {
				let response = await ScheduleService.update(this.projectId, scheduleInfo);
				if (response && response.status === 200) {
					console.log('Update schedule info succesfully');
					this.onCheckScheduleStatus(scheduleInfo['status']);
				}
				return true;
			} catch (error) {
				console.log(`Update schedule info failed: ${error}`);
				return false;
			}
		},

		/**
		 * Filter Calendar and DateTimePicker show data
		 * @param {Object} data: API's response data
		 */
		_filterEventCategories(data) {
			// Check if Creator not sending Data, just saved → not showing Schedule Data in Client Menu
			// if (data['status'] === CommonConst.SCREEN_STATUS.SAVE) {
			// 	return;
			// }
			for (let i = 0; i < ScheduleConst.EVENT_CATEGORIES.length; i++) {
				if (
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date'] &&
					data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date']
				) {
					let singleEvent = this.eventCategories[i];
					singleEvent.datePickerData.dateRange.start = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_start_date']
					).format('YYYY/MM/DD');
					singleEvent.datePickerData.dateRange.end = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_end_date']
					).format('YYYY/MM/DD');

					this.eventCategories.splice(i, NUMBER_OBJECT_CHANGE, singleEvent);
				}
				if (data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_date']) {
					let eventCategory = this.eventCategories[i];
					eventCategory.datePickerData.selectedDate = moment(
						data[ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent + '_date']
					).format('YYYY/MM/DD');

					this.eventCategories.splice(i, NUMBER_OBJECT_CHANGE, eventCategory);
				}
				//add code 28/07/2023 by HoaDQ
				const { isEdit, title } = this.checkIsEdited(
					ScheduleConst.EVENT_CATEGORIES[i].scheduleEvent
				);
				if (isEdit) {
					this.eventCategories[i].title = title;
				}
			}
			this.remarksContent = data['comment'];
		},
		//add code checkIsEdited 28/07/2023 by HoaDQ
		checkIsEdited(scheduleEvent) {
			switch (scheduleEvent) {
				case 'creative':
					return {
						isEdit: true,
						title: this.title_creative,
					};
				case 'proposal':
					return {
						isEdit: true,
						title: this.title_proposal,
					};
				default:
					return {
						isEdit: false,
						title: '',
					};
			}
		},
		onCheckScheduleStatus(status) {
			if (status == CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.showBottomButton = true;
				this.isDataExisted = true;
			} else if (status == CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM) {
				this.showBottomButton = false;
				this.isDataExisted = true;
			} else if (status == CommonConst.SCREEN_STATUS.DENY_CONFIRM) {
				this.showBottomButton = false;
				this.showStatusBadge = true;
				this.isDataExisted = true;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.isDataExisted = true;
			}
		},

		onOpenReasonModal() {
			this.$bvModal.show(`${this.scheduleReasonModalId}`);
		},

		async onSendReason(reasonContent) {
			this.$store.commit('setIsAppProcessing', true);

			let scheduleInfo = {
				status: CommonConst.SCREEN_STATUS.DENY_CONFIRM,
				reason: reasonContent,
			};
			let updateResult = await this._updateScheduleData(scheduleInfo);
			if (updateResult) {
				this.reasonContent = '';
				this.showBottomButton = false;
				this.showStatusBadge = true;
				this.scheduleStatus = CommonConst.SCREEN_STATUS.DENY_CONFIRM;

				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'schedule_retry',
					},
					room: this.projectId,
				});
			}
			// // send mail to creator case 15
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「スケジュール」調整依頼が届きました`;
			// this.mailContent = `「スケジュール」調整依頼が\n届きました。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「スケジュール」調整依頼が届きました`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「スケジュール」調整依頼を送りました`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「スケジュール」調整依頼を送りました`;
			// this.mailContent = `「スケジュール」調整依頼を\n送りました。`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);

			this.$store.commit('setIsAppProcessing', false);
		},
	},

	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'schedule_confirm_required':
					this.$store.commit('setIsAppProcessing', true);

					await this._getScheduleInfo(this.projectId);
					this.isDataExisted = true;
					this.showStatusBadge = false;

					this.$store.commit('setIsAppProcessing', false);
					break;

				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.SCHEDULE);
					break;

				default:
					break;
			}
		});
		await this._getScheduleInfo(this.projectId);
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);
		moment.locale('ja');
		this.isShowContent = true;
		this.$store.commit('setIsAppProcessing', false);
	},
};
